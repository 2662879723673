import React, { FC } from 'react'
import classes from './index.module.css'

type LayoutProps = {
  children: React.ReactNode
}

const LayoutEnter: FC<LayoutProps> = ({ children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.blur}>
        <div className={classes.cover}>
          <div className={classes.cover_image}></div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default LayoutEnter