import React, { FC } from 'react'
import classes from './index.module.css'

interface MyModalProps {
  children?: React.ReactNode,
  visible?: boolean,
  setVisible?: (visible: boolean) => void,
}

const MyModal: FC<MyModalProps> = ({ children, visible, setVisible }) => {
  const rootClasses = [classes.myModal]
  if (visible) {
    rootClasses.push(classes.active)
  }

  return (
    <div className={rootClasses.join(' ')} onClick={() => setVisible && setVisible(false)}>
      <div
        className={classes.myModalContent}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}

export default MyModal