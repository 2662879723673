import React, { useState } from 'react'
import classes from './index.module.css'
import MyModal from '../modal/myModal.tsx'

const NewForms = () => {
  let [modalOne, setModalOne] = useState(false)
  let [modalTwo, setModalTwo] = useState(false)
  let [modalThree, setModalThree] = useState(false)
  let [modalFour, setModalFour] = useState(false)

  function modalWindowOne(e: React.MouseEvent) {
    e.stopPropagation()
    setModalOne(true)
  }

  function modalWindowTwo(e: React.MouseEvent) {
    e.stopPropagation()
    setModalTwo(true)
  }

  function modalWindowThree(e: React.MouseEvent) {
    e.stopPropagation()
    setModalThree(true)
  }

  function modalWindowFour(e: React.MouseEvent) {
    e.stopPropagation()
    setModalFour(true)
  }

  function projectsLinksOne() {
    window.open('http://water-database.bunch-of-stuff.ru')
  }

  function projectsLinkTwo() {
    window.open('http://water-shop.bunch-of-stuff.ru')
  }

  function projectsLinkThree() {
    window.open('http://art.bunch-of-stuff.ru')
  }

  function projectsLinkFour() {
    window.open('http://dashboard.bunch-of-stuff.ru')
  }

  return (
    <>
        <div className={classes.containerForms}>
        {/*<h1 className={classes.formTitle}>Projects</h1>*/}
          <div className={classes.containerPics}>

            <div className={classes.formImage} onClick={projectsLinkThree}>
              <div className={classes.cardOne} onClick={modalWindowOne}>
                <span>Info</span>
              </div>
            </div>

            <div className={classes.formImage} onClick={projectsLinksOne}>
              <div className={classes.cardTwo} onClick={modalWindowTwo}>
                <span>Info</span>
              </div>
            </div>

            <div className={classes.formImage} onClick={projectsLinkFour}>
              <div className={classes.cardThree} onClick={modalWindowThree}>
                <span>Info</span>
              </div>
            </div>

            <div className={classes.formImage} onClick={projectsLinkTwo}>
              <div className={classes.cardFour} onClick={modalWindowFour}>
                <span>Info</span>
              </div>
            </div>

          </div>
        </div>

      <MyModal visible={modalOne} setVisible={setModalOne}>
        <div className={classes.ModalContainer}>
          <span className={classes.ModalTitle}>World Art</span>
          <span className={classes.ModalText}>Next JS/TypeScript/SCSS</span>
          <span className={classes.ModalText}>Static website about objects of art,
              in particular about classical sculpture, stored in various world-famous museums.</span>
          <span className={classes.ModalLink}>GitHub: https://github.com/VladPashkovsky/art</span>
        </div>
      </MyModal>

      <MyModal visible={modalTwo} setVisible={setModalTwo}>
        <div className={classes.ModalContainer}>
          <span className={classes.ModalTitle}>Water Database</span>
          <span className={classes.ModalText}>TypeScript/Redux Toolkit/Express JS/Prisma ORM</span>
          <span className={classes.ModalText}>Full Stack application with JWT Authentication. Database of a company that sells water.</span>
          <span className={classes.ModalLink}>GitHub: https://github.com/VladPashkovsky/water_database</span>
        </div>
      </MyModal>

      <MyModal visible={modalThree} setVisible={setModalThree}>
        <div className={classes.ModalContainer}>
          <span className={classes.ModalTitle}>Admin Dashboard</span>
          <span className={classes.ModalText}>JS/SCSS/Firebase</span>
          <span className={classes.ModalText}>Simple admin dashboard with Firebase as backend.</span>
          <span className={classes.ModalLink}>GitHub: https://github.com/VladPashkovsky/admin_dashboard</span>
        </div>
      </MyModal>

      <MyModal visible={modalFour} setVisible={setModalFour}>
        <div className={classes.ModalContainer}>
          <span className={classes.ModalTitle}>Water Shop</span>
          {/*<span className={classes.ModalText}>TypeScript/Redux Toolkit/Express JS/Prisma ORM</span>*/}
          <span className={classes.ModalText}>Under Construction.</span>
          {/*<span className={classes.ModalLink}>GitHub: https://github.com/VladPashkovsky/admin_dashboard</span>*/}
        </div>
      </MyModal>

    </>
  )
}

export default NewForms