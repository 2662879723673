import { FC } from 'react'
import classes from './index.module.css'

export const SiteName: FC = () => {
  function bunchActive() {
    const toggle = document.getElementById('h1')!
    toggle.classList.toggle('active')
  }

  return (
    <div className={classes.siteName_block}>
      <h1 data-text="bunch-of-stuff" onClick={bunchActive}><span>bunch-0f-stuff</span></h1>
    </div>
  )
}


