import React, { FC } from 'react'
import classes from '../gitIcon/index.module.css'

const GitIcon: FC = () => {
  function goToGit() {
    window.open('https://github.com/VladPashkovsky')
  }

  return (
    <div className={classes.git_container}>
      <div className={classes.menu_img}></div>
      <div className={classes.git_img} onClick={goToGit}></div>
    </div>

  )
}

export default GitIcon