import React, { FC } from 'react'
import classes from './index.module.css'

const ShiningText: FC = () => {
  return (
    <div className={classes.text_container}>
      <div className={classes.text_first}>Choose the project or GET LOST!!!</div>
    </div>
  )
}

export default ShiningText