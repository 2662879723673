import LayoutEnter from './components/layoutEnter/layoutEnter.tsx'
import { SiteName } from './components/siteName/siteName.tsx'
import { animated, useSpring, useTransition } from '@react-spring/web'
import { useLocation } from 'react-router-dom'
import ShiningText from './components/shiningText/shiningText.tsx'
import GitIcon from './components/gitIcon/gitIcon.tsx'
import NewForms from './components/newForms/newForms.tsx'


function App() {
  const location = useLocation()

  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
  })

  const WaterDatabaseProp = useSpring({
    from: { x: -300, opacity: 0 },
    to: {x: 0, opacity : 1},
    config: { duration: 500 },
    delay: 1000,
  })

  return (transitions((style) =>
    <LayoutEnter>
      <div style={{position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <SiteName />
        <GitIcon />
      </div>
      <div style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2.5vh'}}>
        <ShiningText />
        <animated.div style={WaterDatabaseProp}>
          <NewForms />
        </animated.div>
      </div>
    </LayoutEnter>
  ))
}

export default App
